import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo-title.svg'
import HeaderLogoMobile from 'icons/logo.svg'

export const HeroLogo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(236.36)};
  height: ${calculateResponsiveSize(60)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: white;
  position: fixed;
  left: 50%;
  transform: translateY(calc(100vh - ${calculateResponsiveSize(180)}))
    translateX(-50%);

  ${mediaMax('mobile')} {
    display: none;
  }
`

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(190)};
  height: ${calculateResponsiveSize(49)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--blue);

  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: 0s;

  ${mediaMax('mobile')} {
    opacity: 1;

    width: 36px;
    height: 60px;
    -webkit-mask: url(${HeaderLogoMobile}) no-repeat;
    -webkit-mask-size: contain;
    left: 25px;
    transform: translateX(0%);
    position: absolute;
  }
`
export const RightSide = styled.div`
  position: fixed;

  right: ${calculateResponsiveSize(17)};
  display: flex;
  gap: ${calculateResponsiveSize(28)};
  align-items: center;
  transition: 1s;
  height: ${calculateResponsiveSize(110)};

  z-index: 102;
  &.homeStart {
    transform: translateY(-${calculateResponsiveSize(110)});
  }
  ${mediaMax('mobile')} {
    right: 25px;
    height: 120px;
    &.homeStart {
      transform: translateY(-120px);
    }
  }
`
export const Wrap = styled.header`
  transition: transform 1s, top 1s;
  transition-timing-function: ease, linear;
  display: flex;
  z-index: 105;
  padding: ${calculateResponsiveSize(40)} 0 ${calculateResponsiveSize(32)};
  justify-content: center;
  align-items: flex-start;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: ${calculateResponsiveSize(120)};

  &.open {
    ${RightSide} {
      gap: ${calculateResponsiveSize(130)};
    }
  }
  &.home {
    ${Logo} {
      transform: translateY(calc(100vh)) translateX(-50%);
      opacity: 1;
      transition: width 0s, height 0s, transform 1s, top 1s, left 0s, opacity 1s;
    }
    ${HeroLogo} {
      left: 50%;
      transform: translateY(calc(100vh + ${calculateResponsiveSize(180)}))
        translateX(-50%);
      opacity: 0;
      transition: transform 1s, opacity 1s;
    }
  }

  &.homeStart {
    ${Logo} {
      width: ${calculateResponsiveSize(190)};
      height: ${calculateResponsiveSize(49)};
      transform: translateY(calc(0vh + ${calculateResponsiveSize(40)}));
      left: 50%;
      transform: translateX(-50%);
      background: var(--white);
      cursor: default;
      pointer-events: none;
      opacity: 0; //
      &.hide {
        transform: translateY(calc(100vh + 100%)) translateX(-50%);
      }
    }
    ${HeroLogo} {
      left: 50%;
      opacity: 1;
      transform: translateY(calc(100vh - ${calculateResponsiveSize(180)}))
        translateX(-50%);
      &.hide {
        transform: translateY(calc(100vh + 100%)) translateX(-50%);
      }
    }
  }
  &:not(.home) {
    ${Logo} {
      width: ${calculateResponsiveSize(190)} !important;
      height: ${calculateResponsiveSize(49)} !important;
      transform: translateX(-50%) !important;
      background: var(--blue) !important;
      left: 50%;
      opacity: 1;
    }
  }

  ${mediaMax('mobile')} {
    height: 120px;
    padding: 25px 0 32px;
    align-items: center;
    position: absolute;
    &.home {
      ${Logo} {
        transform: translateX(0%) translateY(calc(100vh));
        transition: width 1s, height 1s, transform 1s, top 1s;
      }
    }
    &.homeStart {
      ${Logo} {
        background: var(--blue);
        width: 236px;
        height: 60px;
        transform: translateX(0%) translateY(-120px) !important;
        &.hide {
          transform: translateX(0%) translateY(-120px) !important;
        }
      }
    }
    &:not(.home) {
      ${Logo} {
        width: 36px !important;
        height: 60px !important;
        -webkit-mask: url(${HeaderLogoMobile}) no-repeat;
        -webkit-mask-size: contain;
        left: 25px;
        transform: translateX(0%) !important;
      }
    }
  }
`
