import React, { createContext, useCallback, useState, useEffect } from "react";

export const ThemeContext = createContext();

const ThemeProvider = ({ children, location, scrollerSmoother }) => {
  const [siteTheme, setSiteTheme] = useState("");
  const [showSplash, setShowSplash] = useState(true);

  const setTheme = useCallback((name) => {
    setSiteTheme(name);
  }, []);

  useEffect(() => {
    if (showSplash && location.pathname !== "/") {
      setShowSplash(false);
    }
  }, [location, showSplash, setShowSplash]);

  return (
    <ThemeContext.Provider
      value={{
        siteTheme,
        setTheme,
        showSplash,
        setShowSplash,
        scrollerSmoother,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
