import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import {
  calculateResponsiveSize,
  calculateResponsiveSizeH,
} from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import CloseIcon from 'icons/closeBase.svg'
import ArchiIcon from 'icons/start-archi.svg'
import HomeIcon from 'icons/start-home.svg'
import ResiIcon from 'icons/start-resi.svg'
import LocationIcon from 'icons/start-location.svg'
import AmenIcon from 'icons/start-amen.svg'
import PentIcon from 'icons/start-pent.svg'
import instagramIcon from 'icons/instagram.svg'

export const MenuWrap = styled.div`
  height: 100%;
  width: ${calculateResponsiveSizeH(320)};
  position: fixed;
  background: var(--white);
  color: #fff;
  top: 0;
  right: 0;
  z-index: 20;
  padding: ${calculateResponsiveSizeH(40)} ${calculateResponsiveSizeH(40)}
    ${calculateResponsiveSizeH(20)} ${calculateResponsiveSizeH(40)};
  transform: translateX(100%);
  transition: 1s;

  display: flex;
  flex-direction: column;
  ${mediaMax('mobile')} {
    width: 100%;
    padding: 25px;
    height: 100%;
  }
`

export const Item = styled((props) => <Link {...props} />)`
  color: var(--blue);
  font-family: 'HW Atlantic';
  font-size: ${calculateResponsiveSizeH(24)};
  font-weight: 400;
  line-height: ${calculateResponsiveSizeH(24)};
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  padding: 2px 0;
  border-bottom: 1px solid transparent;
  transition: 0.5s;
  &.secondary {
    font-size: ${calculateResponsiveSizeH(14)};
    letter-spacing: ${calculateResponsiveSizeH(2.8)};
  }

  ${mediaMax('mobile')} {
    font-size: 26px;
    line-height: 26px;
    letter-spacing: 0em;
    width: 80%;

    &.secondary {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

export const Li = styled.li`
  color: var(--blue);
  position: relative;

  &:before {
    content: '';
    width: ${calculateResponsiveSizeH(0)};
    height: ${calculateResponsiveSizeH(1.5)};
    display: block;
    position: absolute;
    background: var(--blue);
    transition: 0.5s;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    ${Item} {
      font-size: ${calculateResponsiveSizeH(25)};
      line-height: ${calculateResponsiveSizeH(25)};
    }
  }
  ${mediaMax('mobile')} {
    &:before {
      width: 0;
      height: 0;
    }
    &:hover {
      ${Item} {
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
`
export const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 19;
  visibility: hidden;

  background: #000;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
`

export const Primary = styled.ul`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: ${calculateResponsiveSizeH(19)};

  margin-left: -${calculateResponsiveSizeH(40)};
  margin-right: -${calculateResponsiveSizeH(40)};
  margin-top: auto;
  ${Li} {
    &:after {
      content: '';
      width: ${calculateResponsiveSizeH(19)};
      height: ${calculateResponsiveSizeH(24)};
      display: block;
      position: absolute;
      background: var(--blue);
      -webkit-mask: url(${HomeIcon}) no-repeat;
      -webkit-mask-size: contain;
      transition: 0.5s;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(100%);
    }
    padding: 0 ${calculateResponsiveSizeH(40)};
    height: ${calculateResponsiveSizeH(24)};

    &:nth-child(2) {
      &:after {
        -webkit-mask: url(${ArchiIcon}) no-repeat;
        -webkit-mask-size: contain;
        width: ${calculateResponsiveSizeH(24)};
      }
    }
    &:nth-child(3) {
      &:after {
        -webkit-mask: url(${ResiIcon}) no-repeat;
        -webkit-mask-size: contain;
        width: ${calculateResponsiveSizeH(24)};
      }
    }
    &:nth-child(4) {
      height: ${calculateResponsiveSizeH(50)};
      &:after {
        -webkit-mask: url(${PentIcon}) no-repeat;
        -webkit-mask-size: contain;
        width: ${calculateResponsiveSizeH(24)};
      }
    }
    &:nth-child(5) {
      &:after {
        -webkit-mask: url(${AmenIcon}) no-repeat;
        -webkit-mask-size: contain;
        width: ${calculateResponsiveSizeH(24)};
      }
    }
    &:nth-child(6) {
      &:after {
        -webkit-mask: url(${LocationIcon}) no-repeat;
        -webkit-mask-size: contain;
        width: ${calculateResponsiveSizeH(30)};
        height: ${calculateResponsiveSizeH(19)};
      }
    }
    &:hover {
      &:before {
        width: ${calculateResponsiveSizeH(25)};
      }
      &:after {
        transform: translateY(-50%) translateX(-${calculateResponsiveSizeH(16)});
      }
    }
  }

  ${mediaMax('mobile')} {
    gap: 25px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 90px;
    ${Li} {
      padding: 0;
      height: 20px;

      &:after {
        right: 25px;
        width: 26px;
        height: 26px;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        &:after {
          width: 26px;
          height: 26px;
          -webkit-mask-position-y: center;
          mask-position-y: center;
        }
      }
      &:nth-child(4) {
        height: 50px;
      }
      &:hover {
        &:after {
          transform: translateY(-50%) translateX(100%);
        }
      }
    }
  }
`

export const Secondary = styled.ul`
  color: var(--blue);

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: ${calculateResponsiveSizeH(4)};

  margin-bottom: auto;
  margin-top: ${calculateResponsiveSizeH(42)};
  margin-left: -${calculateResponsiveSizeH(16)};
  ${mediaMax('mobile')} {
    gap: 5px;
    margin-top: auto;
    margin-left: -16px;
  }
`

export const Wrapper = styled.div`
  z-index: 101;
  position: relative;
  &.open {
    ${MenuWrap} {
      transform: translateX(0);
    }
    ${Overlay} {
      opacity: 0;
      visibility: visible;
    }
  }

  ${mediaMax('mobile')} {
    &.open {
      ${MenuWrap} {
        transform: translateX(0);
      }
      ${Overlay} {
        display: none;
      }
    }
  }
`

export const Text = css`
  font-family: 'GT Ultra';
  font-size: ${calculateResponsiveSizeH(9)};
  font-weight: 300;
  line-height: ${calculateResponsiveSizeH(10)};
  letter-spacing: ${calculateResponsiveSizeH(2)};
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;

  color: var(--blue);
  ${mediaMax('mobile')} {
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 2px;
  }
`
export const Links = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${calculateResponsiveSizeH(6)};

  ${mediaMax('mobile')} {
    gap: 10px;
  }
`

export const LegalLink = styled((props) => <Link {...props} />)`
  ${Text}
  font-weight: 300;
`
export const LinkItem = styled.a`
  ${Text}
  font-weight: 300;
`
export const Icon = styled.a`
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;

  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;

  width: ${calculateResponsiveSize(12)};
  height: ${calculateResponsiveSize(12)};
  display: block;
  cursor: pointer;
  background: var(--blue);

  mask-image: url(${instagramIcon});
  -webkit-mask-image: url(${instagramIcon});
  margin-left: ${calculateResponsiveSize(16)};
  margin-top: ${calculateResponsiveSize(8)};

  ${mediaMax('mobile')} {
    width: 12px;
    height: 12px;
    margin-left: 16px;
  }
`
