import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby-link'
import CloseIcon from 'icons/close.svg'

export const Wrap = styled.div`
  .cookies-banner {
    position: fixed;
    background: #001e60e5;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    color: white;

    height: ${calculateResponsiveSize(60)};
    padding: ${calculateResponsiveSize(16)};
    font-family: 'Avenir Next';
    font-weight: 400;
    font-size: ${calculateResponsiveSize(14)};
    line-height: ${calculateResponsiveSize(16)};
    letter-spacing: 0em;
    width: 100%;
    bottom: ${calculateResponsiveSize(0)};
    opacity: 0;
    transition: 0.5s;
  }
  &.show {
    .cookies-banner {
      opacity: 1;
    }
  }
  .cookies-banner-btns {
    position: absolute;
    right: ${calculateResponsiveSize(20)};
  }
  .cookies-banner-accept-btn {
    display: none;
  }
  .cookies-banner-decline-btn {
    -webkit-mask-image: url(${CloseIcon});
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;

    mask-image: url(${CloseIcon});
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background: white;
    border: none;

    height: ${calculateResponsiveSize(28)};
    width: ${calculateResponsiveSize(28)};
    cursor: pointer;
    text-indent: -1000px;
    transition: 1s;
    &:hover {
      transform: scale(1.1);
    }
  }

  ${mediaMax('mobile')} {
    .cookies-banner-btns {
      position: static;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .cookies-banner-accept-btn {
      display: flex;
      font-family: 'Avenir Next';
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: center;
      padding: 6px 10px;
      text-transform: uppercase;
      border-radius: 36px;
      border: none;
      margin-left: 10px;
      color: #292929;
    }
    .cookies-banner-decline-btn {
      display: none;
    }
    .cookies-banner {
      height: 50px;
      padding: 0px 20px;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      left: 0;
      bottom: 0px;
    }
  }
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(6)};
`

export const LinkItem = styled((props) => <Link {...props} />)`
  color: var(--white);
  font-family: 'Avenir Next';
  font-weight: 400;

  font-size: ${calculateResponsiveSize(14)};
  line-height: ${calculateResponsiveSize(16)};
  background: transparent;
  border: none;
  cursor: pointer;

  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 16px;
  }
`
