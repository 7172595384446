import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

import MenuIcon from 'icons/menu.svg'

export const MenuButtonIcon = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${calculateResponsiveSize(6)};
  width: ${calculateResponsiveSize(26)};

  span {
    display: flex;
    width: ${calculateResponsiveSize(26)};
    height: ${calculateResponsiveSize(2)};
    background: var(--blue);
    transition: 0.5s;
    position: relative;
  }
  span:first-child {
    display: flex;
    width: ${calculateResponsiveSize(20)};
  }
  &:not(.open):hover {
    span:first-child {
      width: ${calculateResponsiveSize(26)};
    }
  }

  &.open {
    span {
      transform: rotate(45deg);
      top: -${calculateResponsiveSize(4)};
      width: ${calculateResponsiveSize(30)};
    }
    span:first-child {
      transform: rotate(-45deg);
      top: ${calculateResponsiveSize(4)};
      width: ${calculateResponsiveSize(30)};
    }
    &:hover {
      span {
        width: ${calculateResponsiveSize(26)};
      }
    }
  }

  ${mediaMax('mobile')} {
    width: 26px;
    gap: 6px;

    span {
      width: 26px;
      height: 2px;
    }
    span:first-child {
      width: 26px;
    }
    &:not(.open):hover {
      span:first-child {
        width: 26px;
      }
    }
    &.open {
      span {
        top: -4px;
        width: 30px;
      }
      span:first-child {
        top: 4px;
        width: 30px;
      }
      &:hover {
        span {
          width: 26px;
        }
      }
    }
  }
`
