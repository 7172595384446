import React /* , { useState, useEffect }  */ from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'helpers/gsap'
import ThemeProvider from '../../helpers/ThemeProvider'
//import HeaderTransition from '../ui/header-transition'
import Header from 'components/header'
import Footer from 'components/footer'
import Menu from '../header/menu'
import NavigationNext from '../ui/navigation-next'
//import MainIntro from '../ui/main-intro'
import CookiesBanner from '../ui/cookies-banner'
import RightPart from '../header/right-part'

import 'helpers/fonts.css'
import GlobalStyle from 'helpers/globalStyles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'
import useWindowSize from '../../helpers/useWindowSize'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            {/*  <HeaderTransition status={status}> */}
            <LayoutWrapper>{children}</LayoutWrapper>
            {/*  </HeaderTransition> */}
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props
  const refWrapper = React.useRef(null)
  const isHomePage = location.pathname === '/' && location.hash !== '#building'
  let tr
  let prevScrollPos

  const scrollerSmoother = React.useRef(null)

  const { isMobile } = useWindowSize()

  React.useLayoutEffect(() => {
    const header = document.querySelector('#rightPart')
    prevScrollPos = window?.pageYOffset

    let gsapContext = gsap.context(() => {
      scrollerSmoother.current = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 2,
        effects: true,
        normalizeScroll: { allowNestedScroll: true },
      })
      //pin
      gsap.utils.toArray('.pin').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          pinSpacing: false,
          pin: true,
          start: 'bottom bottom',
          anticipatePin: 1,
        })
      })
      //fadeIn
      gsap.utils.toArray('.fadeIn').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          start: 'top 90%',
          end: '+=1000%',
          toggleClass: {
            targets: container,
            className: 'visible',
            toggle: 'add',
          },
          // once: true,
        })
      })
      //parallax
      gsap.utils.toArray('.parallax').forEach(function (container) {
        scrollerSmoother.current.effects(container.querySelector('.content'), {
          speed: '0.5s',
        })
      })
    }, refWrapper)

    // document.addEventListener('scroll', onScroll)

    return () => {
      gsapContext.revert()
    }
  }, [children])

  /*  const onScroll = (e) => {
    const header = document.querySelector('#rightPart')

    const delta = window.pageYOffset

    const startPoint = document.getElementById('startPoint')
    const hh = header?.clientHeight || 0

    const currentScrollPos = window.pageYOffset

    if (delta - startPoint.offsetTop > 0) {
      if (delta - startPoint.offsetTop <= hh) {
        header.style.top = `-${delta - startPoint.offsetTop}px`
      } else {
        header.style.top = `-${hh}px`
      }

      if (currentScrollPos <= prevScrollPos) {
        header.style.top = 0
      }
      prevScrollPos = currentScrollPos
    }
  } */

  return (
    <ThemeProvider scrollerSmoother={scrollerSmoother} location={location}>
      <div className="home-wrapper" ref={refWrapper}>
        {/*  {isHomePage && isFirstLoad && (
          <MainIntro setIsFirstLoad={setIsFirstLoad} />
        )} */}
        <CookiesBanner />

        <RightPart className={[isHomePage ? 'home' : '']} />
        <Menu />
        <div className="home-content">
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <Header
                isHomePage={isHomePage}
                className={[isHomePage ? 'home homeStart' : '']}
                id={'header'}
              />
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
          <NavigationNext />
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
