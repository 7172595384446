import React, { useContext } from 'react'
import { useLocation } from '@reach/router'

import { ThemeContext } from '../../helpers/ThemeProvider'

import { Wrap, Logo, HeroLogo } from './index.styled'

const Header = ({ className, isHomePage, ...props }) => {
  const { siteTheme } = useContext(ThemeContext)

  return (
    <Wrap className={[className, siteTheme].join(' ')} {...props}>
      <Logo id={'logo'} to={'/'} />
      {isHomePage && <HeroLogo id={'heroLogo'} />}
    </Wrap>
  )
}
export default Header
