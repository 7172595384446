import React from 'react'
import { LegalLink, Links, LinkItem } from './index.styled'
import fhnFile from 'static/fairhousingnotice.pdf'
import sopFile from 'static/NY-StandardOperatingProcedures.pdf'

const FooterSecondary = ({ onClose = () => null }) => {
  return (
    <Links onClick={onClose}>
      <LegalLink to={'/legal'}>Legal</LegalLink>
      <LinkItem href={fhnFile} target={'_blank'} rel="noreferrer">
        FAIR HOUSING NOTICE
      </LinkItem>
      <LinkItem href={sopFile} target={'_blank'} rel="noreferrer">
        STANDARD OPERATING PROCEDURES
      </LinkItem>
    </Links>
  )
}

export default FooterSecondary
