import React from 'react'
import MenuButton from './menu-button'
import ButtonWithDecor from '../ui/button-with-decor'
import { RightSide } from './index.styled'
//import { ScrollSmoother } from 'helpers/gsap'
import { ThemeContext } from 'helpers/ThemeProvider'

const RightPart = ({ className }) => {
  const { scrollerSmoother } = React.useContext(ThemeContext)

  const onClose = () => {
    /*  let scrollerSmoother = ScrollSmoother.create({
      content: '.home-content',
      wrapper: '.home-wrapper',
      smooth: 2,
      effects: true,
    }) */

    document.querySelector('#menu')?.classList.remove('open')

    const header = document.querySelector('#header')
    header?.classList?.remove('open')

    const btn = document.querySelector('#menu-button')
    btn?.classList.remove('open')

    scrollerSmoother.current.paused(false)
  }

  return (
    <RightSide id={'rightPart'} className={className}>
      <ButtonWithDecor
        title={'contact'}
        noBorder
        to={'/contact'}
        onClick={onClose}
      />
      <MenuButton />
    </RightSide>
  )
}

export default RightPart
