import React from 'react'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

import { Container, Right } from './index.styled'

const NavigationNext = ({ layout }) => {
  const {
    menuContent: {
      content: { primary, secondary },
    },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          primary {
            text
            url
          }
          secondary {
            text
            url
          }
        }
      }
    }
  `)
  const links = [
    ...primary.filter((item) => item.url !== '/#building'),
    ...secondary,
  ]
  const location = useLocation()

  const index = links.findIndex(
    ({ url }) =>
      location.pathname === url ||
      (url !== '/' && location.pathname.startsWith(url)),
  )

  if (index === -1 || location.pathname === '/contact/') return null

  const next = index + 1 >= links.length ? links[0] : links[index + 1]

  return (
    <Container className={[layout].join(' ')}>
      <Right to={next.url}>{next.text}</Right>
    </Container>
  )
}

export default NavigationNext
