import React from 'react'
import { Link } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

import ArrowIcon from 'icons/arrow.svg'

export const Item = css`
  font-family: 'HW Atlantic';
  font-size: ${calculateResponsiveSize(36)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(43)};
  letter-spacing: -${calculateResponsiveSize(1)};

  color: var(--blue);
  text-decoration: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  transition: 0.5s;

  &:before {
    content: '';
    margin-left: ${calculateResponsiveSize(16)};
    background: var(--blue);
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    -webkit-mask-image: url(${ArrowIcon});

    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-image: url(${ArrowIcon});

    width: ${calculateResponsiveSize(17 * 1.5)};
    height: ${calculateResponsiveSize(24 * 1.5)};
    display: block;
    transform: rotate(0deg);
  }

  ${mediaMax('mobile')} {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0em;

    &:before {
      -webkit-mask-position: center -1px !important;
      mask-position: center -1px !important;
      width: 24px;
      height: 30px;
      margin-left: 0;
    }
  }
`

export const Right = styled((props) => <Link {...props} />)`
  ${Item}
  flex-direction: row-reverse;
  &:before {
    transform: rotate(-90deg);
    -webkit-mask-position: center bottom ${calculateResponsiveSize(-1)};
    mask-position: center bottom ${calculateResponsiveSize(-1)};
  }
  ${mediaMax('mobile')} {
    flex-direction: column-reverse;
    text-align: center;
    &:before {
      -webkit-mask-position: center bottom -1px !important;
      mask-position: center bottom -1px !important;
    }
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${calculateResponsiveSize(60)} ${calculateResponsiveSize(96)};
  background: transparent;

  ${mediaMax('mobile')} {
    justify-content: center;
    padding: 0 25px 0;
  }
`
