import React from 'react'
//import { ScrollSmoother } from 'helpers/gsap'
import { ThemeContext } from 'helpers/ThemeProvider'

import { MenuButtonIcon } from './index.styled'

const MenuButton = ({ layout = '' }) => {
  const { scrollerSmoother } = React.useContext(ThemeContext)

  const onClick = () => {
    /*  let scrollerSmoother = ScrollSmoother.create({
      content: '.home-content',
      wrapper: '.home-wrapper',
      smooth: 2,
      effects: true,
    }) */

    const menu = document.querySelector('#menu')
    menu.classList.toggle('open')

    const header = document.querySelector('#header')
    header.classList.toggle('open')

    const btn = document.querySelector('#menu-button')
    btn.classList.toggle('open')

    if (header.classList.contains('open')) {
      // document.body.style.overflow = 'hidden'
      scrollerSmoother.current.paused(true)
    } else {
      // document.body.style.overflow = 'initial'
      scrollerSmoother.current.paused(false)
    }
  }

  return (
    <MenuButtonIcon onClick={onClick} className={layout} id={'menu-button'}>
      <span />
      <span />
    </MenuButtonIcon>
  )
}

export default MenuButton
